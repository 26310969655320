import "./main.scss"

import { ready } from "js/utils.js"
import "blissfuljs/bliss.shy.js"
import "blissfuljs/bliss._.js"

const $ = window.Bliss
const $$ = $.$

import _throttle from "lodash/throttle"
import { Navigation } from "./js/navigation"
import { Gallery } from "js/gallery"
import { tns } from "tiny-slider/src/tiny-slider"
const BigPicture = require("bigpicture")

ready(() => {
  //tiny slider single gallery
  const galleries = $$("[data-gallery]")
  if (galleries.length) {
    galleries.forEach((gallery) => {
      tns({
        container: gallery,
        items: 1,
        speed: 300,
        mouseDrag: true,
        controls: false,
        nav: true,
        navPosition: "bottom",
      })
    })
  }

  //tiny slider
  if ($$("[data-tiny-slider]").length) {
    const handleSlide = (info) => {
      const offset = Math.floor(info.items / 2)
      const active = info.index + offset
      //const previous = info.indexCached + offset
      //info.slideItems[previous].classList.remove("tns-slide__center-item")
      //remove center-item class was not enough,
      //because the cloned elements copy also the center-item-class
      $$(
        ".tns-slide__center-item, .tns-slide__left-of-center-item, .tns-slide__right-of-center-item",
      ).forEach((e) => {
        e.classList.remove("tns-slide__center-item")
        e.classList.remove("tns-slide__left-of-center-item")
        e.classList.remove("tns-slide__right-of-center-item")
      })

      info.slideItems[active].classList.add("tns-slide__center-item")
      info.slideItems[active - 1].classList.add(
        "tns-slide__left-of-center-item",
      )
      info.slideItems[active + 1].classList.add(
        "tns-slide__right-of-center-item",
      )
    }
    const ShowSlidesOnDesktop = $$(".businessmodel").length ? 5 : 4
    const slider = tns({
      container: "[data-tiny-slider]",
      items: 1,
      speed: 200,
      mouseDrag: true,
      freezable: false,
      preventActionWhenRunning: true,
      controlsText: ["", ""],
      nav: false,
      responsive: {
        640: {
          items: 3,
        },
        960: {
          items: ShowSlidesOnDesktop,
          startIndex: 3,
        },
      },
      onInit: handleSlide,
    })

    slider.events.on("indexChanged", () => {
      handleSlide(slider.getInfo())
    })

    slider.events.on("newBreakpointEnd", (info) => {
      setTimeout(() => handleSlide(info), 1) //slider didn't update fully on newBreakpointEnd
    })
  }

  //textarea minlength
  $$("textarea").forEach((e) => {
    e.setAttribute("minlength", "12")
  })

  // Mobile Nav Toggle
  new Navigation()

  //scroll direction listener
  const scrolledThreshold = $(".header").offsetHeight + 100
  let lastPageYOffset = 0

  window.addEventListener(
    "scroll",
    _throttle(() => {
      if (window.pageYOffset > scrolledThreshold) {
        document.body.classList.add("scrolled")
        if (window.pageYOffset > lastPageYOffset) {
          document.body.classList.remove("scrolled--up")
          document.body.classList.add("scrolled--down")
        } else {
          document.body.classList.add("scrolled--up")
          document.body.classList.remove("scrolled--down")
        }
      } else {
        document.body.classList.remove("scrolled")
        document.body.classList.remove("scrolled--up")
        document.body.classList.remove("scrolled--down")
      }

      lastPageYOffset = window.pageYOffset
    }, 100),
  )

  const mediaSlider = $$(".media-slider__slide:not(.gallery--none)")

  if (mediaSlider) {
    mediaSlider.forEach((e) => {
      new Gallery(e, {
        interval: 5000,
        autoPlay: true,
        createThumbs: true,
        endless: false,
        visibleSlides: 1,
      })
    })
  }

  //testimonial
  const carousel = $$("[data-carousel]")
  if (carousel) {
    carousel.forEach((e) => {
      new Gallery(e, {
        interval: 5000,
        autoPlay: false,
        createThumbs: true,
        endless: false,
      })
    })
  }

  const videoPopup = document.querySelector("[data-video-popup]")

  function openVideo(e) {
    BigPicture({
      el: e.target,
      vimeoSrc: videoPopup.dataset.vimeosrc,
    })
  }
  if (videoPopup) {
    videoPopup.addEventListener("click", (e) => {
      e.preventDefault()
      openVideo(e)
    })
  }
})
